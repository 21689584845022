import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setVehicle } from '../../../models/Vehicles'
import Layout from '../../../templates/Main'
import Feed from '../../../modules/Feed'
import Heading from '../../../modules/Heading'


const QuotesPage = ({ vid, dispatch, params, bigText }) => {
    if (!vid) {
        useEffect(() => {
            if (params.id) {
                setVehicle(dispatch, params.id)
            }
        }, [params])
    }
    {
        return (
            <Layout id={params.id}>
                <Heading size='h1' text='Your Quotes' bigText={bigText} />
                <Feed type="quote" user='client' />
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        vid: state.Vehicle.vid,
        bigText: state.Vehicle.bigText,
    };
}
export default connect(mapStateToProps)(QuotesPage)
